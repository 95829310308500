<template>
  <div class="min-h-screen bannerAdjust">
    <div
      class="absolute top-0 left-0 w-full h-full bg-no-repeat lg:h-auto bg-adjust"
      style="background-image: url('/images/main-banner.jpg')"
    >
      <div class="container md:pt-5">
        <div class="md:flex md:justify-between">
          <img
            src="/images/main-logo.png"
            alt=""
            class="w-64 mx-auto md:mx-0"
          />
          <!-- <div class="pt-4 text-center">
            <h1
              class="w-64 p-2 mx-auto text-white transition duration-300 ease-linear rounded-lg bg-pharma-blue hover:bg-pharma-orange"
            >
              <a href="tel:1800888313">Call Us : 1 800 888 313</a>
            </h1>
          </div> -->
        </div>
      </div>
      <div class="container pt-20 md:pt-32 lg:pb-10">
        <div class="lg:flex">
          <div class="lg:w-1/2">
            <h1
              class="text-5xl font-semibold text-center uppercase lg:text-6xl lg:text-left text-pharma-blue"
            >
              Passion for <span class="text-pharma-orange">patients</span>
            </h1>
            <!-- <div v-if="!hideEnquiry" class="pt-10">
              <h1
                class="p-2 mx-auto text-center text-white transition duration-200 ease-linear transform rounded-lg shadow hover:bg-pharma-blue lg:mx-0 bg-pharma-orange w-52 hover:-translate-y-2"
              >
                <a href="#" v-scroll-to="{ el: '#element', duration: 1500 }">Enquire Us Now</a>
              </h1>
            </div> -->
          </div>
          <div class="pt-10 lg:w-1/2 lg:pt-0">
            <img
              src="/images/aed.png"
              alt=""
              class="w-full md:w-2/3 lg:w-full xl:w-3/4 md:mx-auto"
            />
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  // props: {
  //   hideEnquiry: {
  //     type: Boolean,
  //     default: false,
  //   },
  // },
  data() {
    return {
      opened: false,
    };
  },
};
</script>

<style>
.bg-adjust {
  background-position-x: 55%;
}

@media (min-width: 1024px) {
  .bannerAdjust {
    min-height: 615px !important;
  }
}
</style>